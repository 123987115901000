import ContractsService from '@infrastructure/contracts.api'

export function handleGetContract({ respondWithContract, respondWithError }) {
  return { execute }

  async function execute(cups) {
    try {
      const contract = await ContractsService.getContract(cups)

      respondWithContract(contract)
    } catch (error) {
      respondWithError()
    }
  }
}
