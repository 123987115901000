
import { CoHeader } from '@holaluz/cosmopolitan'
import { mapGetters } from 'vuex'

export default {
  name: 'TheHeader',
  components: { CoHeader },
  data() {
    return {
      environment: this.$config.env,
    }
  },
  computed: {
    ...mapGetters('user', [
      'userPermissions',
      'isExternalChannel',
      'username',
      'userRole',
    ]),

    salesUserType() {
      return this.isExternalChannel ? 'channel' : 'internal'
    },

    gradientLogo() {
      return this.$featureFlag('pride', false)
        ? 'pride-gradient'
        : 'holaluz-gradient'
    },
  },
  methods: {
    logoutHandler() {
      this.$auth.logout()
    },
  },
}
