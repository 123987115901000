import { dealApiService } from '@modules/deal/infrastructure/deal.api.service'
import type { UpdateDealRequest } from '@modules/deal/domain/stage.types'

type Callbacks = {
  onSuccess: () => void
  onError: (error: string) => void
}

export function handleUpdateCups() {
  return { execute }

  async function execute(
    payload: UpdateDealRequest,
    { onSuccess, onError }: Callbacks
  ) {
    try {
      await dealApiService.updateCups(payload)
      onSuccess()
    } catch (error) {
      if (error.response?.status === 403) {
        return onError('invalid-status')
      }
      return onError(error.response?.data?.error || 'cannot-update-cups')
    }
  }
}
