import { handleListVerifiedOffers } from '@modules/offer/application/list-verified-offers'

async function requestVerifiedOffers({ commit }, cups) {
  function respondWithVerifiedOffers(verifiedOffers) {
    commit('SET_VERIFIED_OFFERS', verifiedOffers)
  }

  function respondWithNoVerifiedOffers() {
    throw new Error('no-verified-offers')
  }

  function respondWithError() {
    throw new Error('api-error')
  }

  await handleListVerifiedOffers().execute(cups, {
    respondWithVerifiedOffers,
    respondWithNoVerifiedOffers,
    respondWithError,
  })
}

export default {
  requestVerifiedOffers,
}
