
import TheHeader from '@components/TheHeader'

export default {
  name: 'LayoutLandscape',

  components: {
    TheHeader,
  },
}
