export function handleGetValidOffer(respondWithValidOffer) {
  return { execute }
  function execute({ originalOffer, value, financingCondition }) {
    const [key, newValue] = Object.entries(value)[0]
    const validValue = Object.assign(value)
    let minValue = null
    let maxValue = null

    const { minLoanYears, maxLoanYears } = financingCondition

    if (key === 'initialInvestment') {
      minValue = 0
      maxValue = originalOffer.totalValue || originalOffer.installationValue
    }
    if (key === 'loanYears') {
      minValue = minLoanYears
      maxValue = maxLoanYears
    }

    if (newValue < minValue) {
      validValue[key] = minValue
    }
    if (newValue > maxValue) {
      validValue[key] = maxValue
    }

    const offer =
      key === 'initialInvestment'
        ? { ...originalOffer, ...validValue }
        : {
            ...originalOffer,
            financingData: { ...originalOffer.financingData, ...validValue },
          }

    respondWithValidOffer({ offer })
  }
}
