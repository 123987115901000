import { solarOpsAxios } from '@infrastructure/apiService'
import { IAssetsCatalogRepository } from '@modules/assets-catalog/domain/assets-catalog.repository'
import {
  formatBatteriesCatalog,
  formatEvChargersCatalog,
  parseInstallationType,
} from '@modules/assets-catalog/infrastructure/assets-catalog.utils'

export const assetsCatalogService: IAssetsCatalogRepository = {
  getAssetsByPostalCodeAndInstallationType: async (
    postalCode: string,
    installationType?: number
  ) => {
    const params: { postal_code: string; tension_type?: string } = {
      postal_code: postalCode,
    }

    if (installationType) {
      params.tension_type = parseInstallationType(installationType)
    }

    const { data: batteries } = await solarOpsAxios.get(
      'assets-catalog/batteries/new-solar/active',
      {
        params,
      }
    )

    const { data: evChargers } = await solarOpsAxios.get(
      'assets-catalog/ev-chargers/new-solar/active',
      {
        params,
      }
    )

    const catalogList = [
      ...formatBatteriesCatalog(batteries),
      ...formatEvChargersCatalog(evChargers),
    ]

    return Object.assign(
      {},
      ...catalogList.map((item) => ({ [item.id]: item }))
    )
  },
}
