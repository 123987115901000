import { setupWorker } from 'msw'
import { handlers } from '../../mocks/handlers'

const initMSW = ({ $config }) => {
  const worker = setupWorker(...handlers)
  const isCypress = typeof window.Cypress !== 'undefined'

  if ($config.isDevelopment && !isCypress) {
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  }
}

export default initMSW
