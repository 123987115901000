const hasSpecs = (state) =>
  state.specs ? Boolean(Object.keys(state.specs).length) : false

const cupsCanAddSolarOffer = (_, getters) => getters.hasOffers

const specFormHasErrors = (state) =>
  Object.values(state.specsFormValidation).some((el) => el.isInvalid === true)

const offers = (state) => state[state.activeOfferContext] || {}
const contract = (state) => state.contract

const offer = (state, getters) => {
  return getters.isSimulatedOfferContext
    ? state.simulatedOffer
    : getters.offers?.[state.selectedOffer] || {}
}

const hasOffers = (_, getters) => Boolean(Object.keys(getters.offers).length)
const hasContract = (_, getters) => Boolean(getters.contract !== null)

const isSingleOffer = (_, getters) => {
  const { hasOffers, offers } = getters

  return hasOffers ? offers.recommended.panels === offers.standard.panels : null
}

const isFinanced = (state) => {
  return state.selectedFinancingCondition !== 'cash'
}

const financingCondition = (state) => {
  return (
    state.financingConditions.find(
      (fc) => fc.id === state.selectedFinancingCondition
    ) || {}
  )
}

const monthlyLoanWithTaxesIsUnderMinQuota = (state, getters) => {
  return state.selectedFinancingCondition === 'cash'
    ? false
    : Number(getters.offer.financingData.monthlyLoanWithTaxes) <
        Number(getters.offer.financingData.minQuota)
}

const isOfferReduced = (state, getters) => {
  return getters.isSimulatedOfferContext
    ? state.simulatedOffer.offerResizeInfo?.startsWith('reduced')
    : getters.offer?.offerResizeInfo?.startsWith('reduced')
}
const isSimulatedOfferContext = (state) => {
  return state.activeOfferContext === 'simulatedOffer'
}

const totalValueWithDiscounts = (_state, getters) => {
  if (!getters.hasDiscounts) {
    return getters.offer.totalValueWithoutDiscounts
  }
  return Object.values(getters.discounts).reduce(
    (acc, discount) => acc - Number(discount),
    getters.offer.totalValueWithoutDiscounts
  )
}

const hasDiscounts = (_state, getters) => {
  return (
    getters.discounts &&
    Object.keys(getters.discounts).length > 0 &&
    Object.values(getters.discounts).some((discount) => discount > 0)
  )
}

const discounts = (state, getters) => {
  return getters.isSimulatedOfferContext
    ? state.specs.discounts ?? {}
    : getters.offer.discounts
}

export default {
  hasSpecs,
  cupsCanAddSolarOffer,
  specFormHasErrors,
  offers,
  offer,
  hasOffers,
  isSingleOffer,
  isFinanced,
  financingCondition,
  monthlyLoanWithTaxesIsUnderMinQuota,
  isOfferReduced,
  isSimulatedOfferContext,
  contract,
  hasContract,
  totalValueWithDiscounts,
  hasDiscounts,
  discounts,
}
