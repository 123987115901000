import { handleValidateUpdateCupsRequest } from '@modules/deal/application/validate-update-cups-request'
import { handleUpdateCups } from '@modules/deal/application/update-cups'
import { sendDatadogError, sendDatadogEvent } from '@ui/plugins/datadog'

async function validateUpdateCupsRequest({ commit }, cups) {
  function onSuccess() {
    sendDatadogEvent({
      name: 'validate-update-cups',
      value: { cups },
    })
    commit('SET_VALIDATION_SUCCESS', true)
    commit('SET_VALIDATION_LOADING', false)
  }

  function onError(error) {
    sendDatadogError({
      name: 'validate-update-cups-error',
      options: { cups, error },
    })
    commit('SET_VALIDATION_ERROR', error)
    commit('SET_VALIDATION_LOADING', false)
  }

  commit('CLEAR_VALIDATION')
  commit('SET_VALIDATION_LOADING', true)

  await handleValidateUpdateCupsRequest().execute(cups, {
    onSuccess,
    onError,
  })
}
async function updateCups({ commit, dispatch, rootState }, cups) {
  function onSuccess() {
    sendDatadogEvent({
      name: 'update-cups',
      value: { cups },
    })
    commit('SET_UPDATE_SUCCESS', true)
    commit('SET_UPDATE_LOADING', false)
    dispatch('info/setOverwrittenCups', true, { root: true })
    dispatch('info/updateCups', cups, { root: true })
  }

  function onError(error) {
    sendDatadogError({
      name: 'update-cups-error',
      options: { cups, error },
    })
    commit('SET_UPDATE_ERROR', error)
    commit('SET_UPDATE_LOADING', false)
  }

  commit('CLEAR_UPDATE')
  commit('SET_UPDATE_LOADING', true)

  await handleUpdateCups().execute(
    {
      oldCups: rootState.info.cups,
      newCups: cups,
      leadId: rootState.info.client.id,
    },
    {
      onSuccess,
      onError,
    }
  )
}

export default { updateCups, validateUpdateCupsRequest }
