import Vue from 'vue'

const responsive = {
  install(vue) {
    const isMobile = () => vue.prototype.$layout.currentBreakpoint === 'mobile'
    vue.prototype.$responsive = Vue.observable({
      isMobile: isMobile(),
    })
    if (process.server) return
    window.addEventListener(
      'resize',
      () => {
        vue.prototype.$responsive.isMobile = isMobile()
      },
      { passive: true }
    )
  },
}

Vue.use(responsive)
