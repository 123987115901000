import { electricityContractService } from '@modules/electricity-contract/infrastructure/electricity-contract.service'

type Parameters = {
  cups: string
}

type Callbacks = {
  onNewContract: () => void
  onUpselling: (contractId: string) => void
  onNetworkError: () => void
}

export function checkIsNewContractOrUpselling() {
  return { execute }

  async function execute(
    { cups }: Parameters,
    { onNewContract, onUpselling, onNetworkError }: Callbacks
  ) {
    try {
      const isNewContract =
        await electricityContractService.hasElectricityContract(cups)

      if (isNewContract) {
        onNewContract()
        return
      }

      const contract = await electricityContractService.getContractByCups(cups)

      onUpselling(contract.id)
    } catch (error) {
      onNetworkError()
    }
  }
}
