/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware, Context } from '@nuxt/types'
import get from 'lodash/get'

// Restrict pages to users that has not permissions

const userPermissions: Middleware = ({ app, redirect, route }: Context) => {
  const permissions = getPermissions(route)

  if (!hasPermission(app, permissions)) {
    return redirect('/')
  }
}

function getPermissions({ matched }: any) {
  // Doc references https://github.com/nuxt/nuxt.js/issues/720
  return [].concat(
    ...matched.map(({ components }: any) =>
      get(
        components.default,
        'options.permissions',
        components.default.permissions
      )
    )
  )
}

function hasPermission(app: any, permissions: string[]) {
  if (!app.$config.isProduction) return true

  return app.$auth.user.roles.some((role: string) => {
    return permissions.includes(role)
  })
}

export default userPermissions
