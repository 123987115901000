const currentStep = (state) => {
  return state.currentStep
}

const totalSteps = (state) => {
  return state.totalSteps
}

export default {
  currentStep,
  totalSteps,
}
