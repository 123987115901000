import finalOffer from '../../cypress/fixtures/finalOffer.json'
import holder from '../../cypress/fixtures/holder.json'
import offer from '../../cypress/fixtures/offer.json'
import offerData from '../../cypress/fixtures/offerData.json'
import publicOfferData from '../../cypress/fixtures/publicOfferData.json'
import potentials from '../../cypress/fixtures/potentials.json'
import radiation from '../../cypress/fixtures/radiation.json'
import supplyPoint from '../../cypress/fixtures/supplyPoint.json'
import financingConditions from '../../cypress/fixtures/financingConditions.json'
import financingData from '../../cypress/fixtures/financingData.json'
import panelsDistribution from '../../cypress/fixtures/panelsDistribution.json'
import solarStage from '../../cypress/fixtures/solarStage.json'
import isOfferable from '../../cypress/fixtures/isOfferable.json'
import currentContract from '../../cypress/fixtures/currentContract.json'
import contractData from '../../cypress/fixtures/contractData.json'
import contractPdf from '../../cypress/fixtures/contractPdf.json'
import verifiedOffers from '../../cypress/fixtures/verifiedOffers.json'
import simulateVerifiedOffer from '../../cypress/fixtures/simulateVerifiedOffer.json'
import client from '../../cypress/fixtures/client.json'
import featureFlags from '../../cypress/fixtures/featureFlags.json'
import batteries from '../../cypress/fixtures/batteries.json'
import evChargers from '../../cypress/fixtures/evChargers.json'

export default {
  finalOffer,
  holder,
  offer,
  offerData,
  publicOfferData,
  potentials,
  radiation,
  supplyPoint,
  financingConditions,
  financingData,
  panelsDistribution,
  solarStage,
  isOfferable,
  currentContract,
  contractData,
  contractPdf,
  verifiedOffers,
  simulateVerifiedOffer,
  client,
  featureFlags,
  batteries,
  evChargers,
}
