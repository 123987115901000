import { solarSalesAxios } from '@infrastructure/apiService'

function factory(service) {
  return {
    async offerPdf(cups) {
      const { data } = await service.get(`${cups}/offer/last/pdf`)
      return data
    },
  }
}

export default factory(solarSalesAxios)
