export function Coordinates({ latitude, longitude }) {
  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  }
}

export const latitudeValues = {
  min: -90,
  max: 90,
}

export const longitudeValues = {
  min: -180,
  max: 180,
}
