import { solarSalesAxios } from '@infrastructure/apiService'

type Callbacks = {
  respondWithSuccess: () => void
  respondWithError: () => void
}

export function handleUnbindContract() {
  return { execute }

  async function execute(
    cups: string,
    { respondWithSuccess, respondWithError }: Callbacks
  ) {
    try {
      await solarSalesAxios.get(`${cups}/contract/unbind`)
      respondWithSuccess()
    } catch (error) {
      respondWithError()
    }
  }
}
