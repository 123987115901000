import { solarOfferService } from '@modules/pdf/infrastructure/solar-offer.service'

type Callbacks = {
  onPdfUrl: (pdfId: string) => void
  onPdfUrlError: () => void
}

export function downloadSolarOfferPdf() {
  return { execute }

  async function execute(
    pdfId: string,
    { onPdfUrl, onPdfUrlError }: Callbacks
  ) {
    try {
      const fileUrl = await solarOfferService.getSolarOfferPdf(pdfId)
      onPdfUrl(fileUrl)
    } catch (error) {
      onPdfUrlError()
    }
  }
}
