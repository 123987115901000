const SET_COVERS = (state, covers) => {
  state.covers = covers
}

const SET_BOUNDS = (state, bounds) => {
  state.bounds = bounds
}

const SET_PANELS = (state, panels) => {
  state.panels = panels
}

const SET_CENTER = (state, center) => {
  state.center = center
}

const SET_OBSTACLES = (state, obstacles) => {
  state.obstacles = obstacles
}

const SET_ERROR = (state, error) => {
  state.error = error
}

export default {
  SET_COVERS,
  SET_BOUNDS,
  SET_PANELS,
  SET_ERROR,
  SET_CENTER,
  SET_OBSTACLES,
}
