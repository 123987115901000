import { potentialService } from '@modules/potential/infrastructure/potential.service'
import { Potential } from '@modules/potential/domain/potential.types'

type Parameters = {
  cups: string
  isExternalChannel: boolean
}

type Callbacks = {
  onPotential: (potential: Potential) => void
  onNoPotential: () => void
  onExternalChannel: () => void
  onNetworkError: () => void
}

export function getPotentialByCups() {
  return { execute }

  async function execute(
    { cups, isExternalChannel }: Parameters,
    { onPotential, onNoPotential, onExternalChannel, onNetworkError }: Callbacks
  ) {
    if (isExternalChannel) {
      onExternalChannel()
      return
    }

    try {
      const potential = await potentialService.getPotentialByCups(cups)

      onPotential(potential)
    } catch (error) {
      if (error.message === 'no potentials found') {
        onNoPotential()
        return
      }

      onNetworkError()
    }
  }
}
