import { drawingService } from '@modules/drawing/infrastructure/drawing.service'
import { Distribution } from '@modules/drawing/domain/drawing.types'

type Callbacks = {
  onSuccess: (distribution: Distribution) => void
  onError: () => void
}

export function handleSendPanels() {
  return { execute }

  async function execute(
    payload: Distribution,
    { onSuccess, onError }: Callbacks
  ) {
    try {
      const data = await drawingService.sendPanels(payload)
      onSuccess(data)
    } catch {
      onError()
    }
  }
}
