import { handleSendCovers } from '@modules/drawing/application/handle-send-covers'
import { handleSendPanels } from '@modules/drawing/application/handle-send-panels'

function updateCovers({ commit }, value) {
  commit('SET_COVERS', value)
}

function updateBounds({ commit }, value) {
  commit('SET_BOUNDS', value)
}

function updatePanels({ commit }, value) {
  commit('SET_PANELS', value)
}

function updateObstacles({ commit }, value) {
  commit('SET_OBSTACLES', value)
}

async function sendCovers({ rootState, state, commit, rootGetters }) {
  commit('SET_PANELS', {})
  commit('SET_ERROR', false)
  function onSuccess(panels) {
    commit('SET_PANELS', panels)
  }

  function onError() {
    commit('SET_ERROR', true)
  }

  const offer = rootGetters['offerOnVisit/offer']

  await handleSendCovers().execute(
    {
      cups: rootState.info.cups,
      pv: offer.panels,
      covers: state.covers,
      bounds: state.bounds,
      obstacles: state.obstacles,
    },
    {
      onSuccess,
      onError,
    }
  )
}

async function sendPanels({ rootState, state, rootGetters, commit }) {
  commit('SET_ERROR', false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function onSuccess() {}

  function onError() {
    commit('SET_ERROR', true)
  }

  const offer = rootGetters['offerOnVisit/offer']

  await handleSendPanels().execute(
    {
      cups: rootState.info.cups,
      pv: offer.panels,
      covers: state.covers,
      panels: state.panels,
      center: state.center,
      obstacles: state.obstacles,
    },
    {
      onSuccess,
      onError,
    }
  )
}

function updateError({ commit }, error) {
  commit('SET_ERROR', error)
}

function updateCenter({ commit }, center) {
  commit('SET_CENTER', center)
}

export default {
  updateCovers,
  updateBounds,
  updatePanels,
  sendCovers,
  sendPanels,
  updateError,
  updateCenter,
  updateObstacles,
}
