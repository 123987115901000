import OfferService from '@infrastructure/offer.api'

type Callbacks = {
  onSimulatedVerifiedOffer: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    simulatedVerifiedOffer: Record<string, any>
  ) => void
  onError: (error: string) => void
}
type ApiErrors = {
  401: string
  404: string
  400: string
  500: string
  503: string
}
export function handlerSimulatedVerifiedOffer() {
  return { execute }

  async function execute(
    specs: Record<string, string | number | boolean | Record<string, number>>,
    { onSimulatedVerifiedOffer, onError }: Callbacks
  ) {
    try {
      const simulatedVerifiedOffer =
        await OfferService.getSimulatedVerifiedOffer(specs)

      onSimulatedVerifiedOffer(simulatedVerifiedOffer)
    } catch (error) {
      const errors: ApiErrors = {
        401: 'unauthorized',
        404: 'offer-not-found',
        400: 'offer-param-request-wrong',
        500: 'api-error',
        503: 'api-error',
      }

      const status = error.response?.status
      if (status) {
        onError(errors[status as keyof ApiErrors])
      }
    }
  }
}
