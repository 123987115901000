const featureFlags = (state) => {
  return state.featureFlags
}

const getFeatureFlag = (state) => (featureName, defaultValue) => {
  if (!state.featureFlags.find) {
    return defaultValue
  }

  const featureFlag = state.featureFlags.find(
    (v) => v.settingKey === featureName
  )

  if (featureFlag) {
    return featureFlag.settingValue
  }

  return defaultValue
}

export default {
  featureFlags,
  getFeatureFlag,
}
