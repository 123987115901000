import { FinancingData } from '@domain/Financing/FinancingData'

export const PANELS_RESIZED_REASON = {
  REDUCED_OFFER_BY_MISSING_NEARBY_CUPS_DATA:
    'reduced-offer-by-missing-nearby-cups-data',
  REDUCED_OFFER_BY_INSUFFICIENT_NEARBY_CUPS:
    'reduced-offer-by-insufficient-nearby-cups',
  REDUCED_OFFER_BY_PMA_RESTRICTIONS: 'reduced-offer-by-pma-restrictions',
}

export const TYPE_OF_OFFER = {
  STANDARD: 'standard',
  RECOMMENDED: 'recommended',
  CUSTOM: 'custom',
}

export function Offer({
  type,
  id,
  panels,
  shadedPanels,
  optimalPanels,
  capex,
  subsidy,
  totalSubsidy,
  subsidies,
  allowedSubsidy = true,
  installationValue,
  installationValueWithDiscount,
  totalValue,
  assets,
  initialInvestment,
  grossCustomerSavingsWithTaxes,
  monthlyGrossCustomerSavingsWithTaxes,
  futureGrossCustomerSavingsWithTaxes = null,
  savingsFormat,
  offerResizeInfo,
  newInvoice,
  financingData = {},
  savingsInYears = {},
  payback = {},
  paybackWithSubsidy = {},
  sharedCups = [],
  monthlySurplusSavings,
  yearlySurplusSavings,
  production,
  fee,
  discounts,
  energyEfficiencyCertificatePriceWithTaxes,
  promotions = [],
}) {
  function getFinancingData(financingData) {
    return Object.keys(financingData).length ? FinancingData(financingData) : {}
  }

  function getDiscounts() {
    let result = {
      battery: 0,
      certificate: 0,
      evCharger: 0,
      panels: 0,
    }
    if (discounts && Object.keys(discounts).length > 0) {
      // delete installation discount when installation is removed from be -> task-182007
      const { panels, installation = 0, ...otherDiscounts } = discounts
      result = {
        ...result,
        ...otherDiscounts,
        panels: panels + installation,
      }
    }
    return result
  }

  function getTotalValueWithoutDiscounts() {
    const totalDiscounts =
      discounts && Object.keys(discounts).length > 0
        ? Object.values(discounts).reduce((sum, discount) => sum + discount, 0)
        : 0

    return Number(totalValue) + Number(totalDiscounts)
  }
  return Object.freeze({
    type,
    id,
    panels,
    shadedPanels,
    optimalPanels,
    capex,
    subsidy,
    totalSubsidy,
    subsidies,
    allowedSubsidy,
    installationValue,
    installationValueWithDiscount,
    totalValue,
    totalValueWithoutDiscounts: getTotalValueWithoutDiscounts(),
    assets,
    initialInvestment,
    grossCustomerSavingsWithTaxes,
    monthlyGrossCustomerSavingsWithTaxes,
    futureGrossCustomerSavingsWithTaxes,
    newInvoice,
    savingsFormat,
    offerResizeInfo: PANELS_RESIZED_REASON[offerResizeInfo] || null,
    financingData: getFinancingData(financingData),
    savingsInYears,
    payback,
    paybackWithSubsidy,
    sharedCups,
    monthlySurplusSavings,
    yearlySurplusSavings,
    production,
    fee,
    discounts: getDiscounts(),
    energyEfficiencyCertificatePriceWithTaxes,
    promotions,
  })
}
