import { coreAxios } from '@infrastructure/apiService'
import { formatSupplyPoint } from '@modules/supply-point/infrastructure/supply-point.utils'
import { ISupplyPointRepository } from '@modules/supply-point/domain'

export const supplyPointService: ISupplyPointRepository = {
  getSupplyPointByCups: async (cups: string) => {
    const { data: supplyPoint } = await coreAxios.get('private/supply-point', {
      params: { cups },
    })
    const { data: holder } = await coreAxios.get(
      `/private/supply-point/${cups}/holder`
    )

    return formatSupplyPoint({
      ...supplyPoint,
      ...holder,
    })
  },
}
