import { verifiedOfferService } from '@modules/offer/infrastructure/verified-offer.service'
import { VerifiedOffer } from '@modules/offer/domain/verified-offer.types'

type Callbacks = {
  respondWithVerifiedOffers: (verifiedOffers: Array<VerifiedOffer>) => void
  respondWithNoVerifiedOffers: () => void
  respondWithError: () => void
}

export function handleListVerifiedOffers() {
  return { execute }

  async function execute(
    cups: string,
    {
      respondWithVerifiedOffers,
      respondWithNoVerifiedOffers,
      respondWithError,
    }: Callbacks
  ) {
    try {
      const verifiedOffers = await verifiedOfferService.listVerifiedOffers(cups)
      respondWithVerifiedOffers(verifiedOffers)
    } catch (error) {
      if (error.response?.status === 404) {
        return respondWithNoVerifiedOffers()
      }

      respondWithError()
    }
  }
}
