import Cookie from 'js-cookie'
import publicRuntimeConfig from '../../public-runtime.config'

const getAuthToken = () => Cookie.get('holaluz_auth_token')

const addAuthTokenHeader = (config) => {
  const token = getAuthToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  window.location.href = `${publicRuntimeConfig.barman}?project=gimlet&isDevelopment=${publicRuntimeConfig.isDevelopment}&localPort=${window.location.port}`
}

export default (instance) =>
  instance.interceptors.request.use(addAuthTokenHeader, undefined)
