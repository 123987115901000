const CHECKSUM_TABLE = 'TRWAGMYFPDXBNJZSQVHLCKE'.split('')

const VALID_CUPS_REGEX = /^[A-Z]{2}\d{16}[A-Z]{2}\d{0,1}[A-Z]{0,1}$/

export const cups = (value: string) => {
  return {
    isValid: isValid(value),
  }
}

function isValid(cups: string) {
  if (!cups) return false

  return cups.match(VALID_CUPS_REGEX) ? isValidChecksum(cups) : false
}

function isValidChecksum(cups: string) {
  const digits = Number(cups.slice(2, 18))
  const expectedChecksum = cups.slice(18, 20)

  const coefficient = digits % 529

  const firstNumber = Math.floor(coefficient / 23)
  const secondNumber = Math.floor(coefficient % 23)

  const firstDigit = CHECKSUM_TABLE[firstNumber]
  const secondDigit = CHECKSUM_TABLE[secondNumber]

  return expectedChecksum === firstDigit + secondDigit
}
