import { handleSubmitManualSignature } from '@modules/contract/application/submit-manual-signature'

function clear({ commit }) {
  commit('CLEAR')
}

async function submitManualSignature({ commit }, { cups, file }) {
  function onSubmitted() {
    commit('SET_SUCCESS', true)
    commit('SET_LOADING', false)
  }

  function onError() {
    commit('SET_ERROR', 'api')
    commit('SET_LOADING', false)
  }

  commit('CLEAR')
  commit('SET_LOADING', true)

  await handleSubmitManualSignature({
    onSubmitted,
    onError,
  }).execute(cups, file)
}

export default { clear, submitManualSignature }
