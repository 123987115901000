import { Plugin } from '@nuxt/types'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import Cookie from 'js-cookie'
import publicRuntimeConfig from '../../public-runtime.config'
import { parseJwt } from '@ui/utils/parseJwt'

const initDatadog: Plugin = () => {
  if (publicRuntimeConfig.hasDatadog) {
    try {
      const datadogConfig = {
        applicationId: '4ac6459f-4a5f-43e4-ad41-8c6ccee47db5',
        clientToken: 'pub90a05c0dc2bae8b74f8b1aa5a2f246c8',
        env: publicRuntimeConfig.datadogEnv,
        site: 'datadoghq.eu',
        service: 'gimlet',
        sessionSampleRate: 100,
      }

      datadogRum.init({
        ...datadogConfig,
        defaultPrivacyLevel: 'allow',
        allowedTracingOrigins: [/https:\/\/.*\.holaluz\.com/],
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
      })

      datadogLogs.init({
        ...datadogConfig,
      })

      const token = Cookie.get('holaluz_auth_token')

      if (token) {
        const user = parseJwt(token)

        datadogRum.setUser({
          id: user.id,
          name: user.fullName,
          email: user.username,
        })
      }

      if (publicRuntimeConfig.isProduction)
        datadogRum.startSessionReplayRecording()
    } catch (error) {
      if (!publicRuntimeConfig.isProduction)
        // eslint-disable-next-line no-console
        console.error('Error loading datadog tracking', error)
    }
  } else if (!publicRuntimeConfig.isProduction)
    // eslint-disable-next-line no-console
    console.info('Datadog tracking DISABLED')
}

export default initDatadog

export function sendDatadogEvent({
  name,
  value = {},
}: {
  name: string
  value: object
}) {
  if (publicRuntimeConfig.hasDatadog) {
    datadogRum.addAction(name, value)
  } else if (!publicRuntimeConfig.isProduction) {
    // eslint-disable-next-line no-console
    console.info(name, value)
  }
}

export function sendDatadogError({
  error,
  name,
  options,
}: {
  error?: string | Error
  name: string
  options: object
}) {
  const sendError = error || new Error(name)
  if (publicRuntimeConfig.hasDatadog) {
    datadogRum.addError(sendError, options)
  } else if (!publicRuntimeConfig.isProduction) {
    // eslint-disable-next-line no-console
    console.error(sendError, options)
  }
}
