import { assetsCatalogService } from '@modules/assets-catalog/infrastructure/assets-catalog.service'
import { AssetsCatalog } from '@modules/assets-catalog/domain/assets-catalog.types'

type Parameters = {
  postalCode: string
  installationType?: number
}

type Callbacks = {
  onAssetsCatalog: (assets: AssetsCatalog) => void
  onNetworkError: (error: string) => void
}

export function getAssetsCatalog() {
  return { execute }

  async function execute(
    { postalCode, installationType }: Parameters,
    { onAssetsCatalog, onNetworkError }: Callbacks
  ) {
    try {
      const assets =
        await assetsCatalogService.getAssetsByPostalCodeAndInstallationType(
          postalCode,
          installationType
        )

      onAssetsCatalog(assets)
    } catch (error) {
      onNetworkError(error)
    }
  }
}
