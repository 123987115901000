
export default {
  name: 'CmbModal',
  props: {
    triggerText: {
      type: String,
      required: true,
    },
    eventLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick(cb) {
      cb()
    },
  },
}
