import { capitalizeText } from '@ui/utils/capitalize-text'

const fullAddress = (state) => {
  const { address, postalCode, city, province } = state.supplyPoint.address

  const formattedAddress = [
    capitalizeText(address || ''),
    postalCode || '',
    capitalizeText(city || ''),
    province ? `(${capitalizeText(province)})` : '',
  ]
    .filter(Boolean)
    .join(' ')

  return formattedAddress
}

const estimatedConsumption = (state) => {
  return state.supplyPoint?.consumptionByPeriod.reduce((sum, period) => {
    return sum + period.amount
  }, 0)
}

export default {
  fullAddress,
  estimatedConsumption,
}
