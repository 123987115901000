export default () => ({
  userTiers: {
    PERSON: 'person',
    MANAGER: 'manager',
    ADMIN: 'admin',
    INTERNAL: 'internal',
    COMMERCIAL: 'commercial',
  },

  salesCompanyChannels: {
    ASSESOR: 'assesor',
    COMPARATOR: 'comparator',
    REPRESENTATIVE: 'representative',
    INTERNAL: 'internal',
  },
})
