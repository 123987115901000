export const initialState = {
  covers: {},
  bounds: {},
  panels: {},
  center: {},
  obstacles: {},
  error: false,
}

export default () => ({ ...initialState })
