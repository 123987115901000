export {
  installationType,
  tension,
  extraAdditionalCost,
  panels,
  shadedPanels,
  azimuth,
  tilt,
  specsSource,
  specsErrors,
  OfferSpecs,
  visitInterest,
} from './OfferSpecs'
