import { createSolarOfferPdf } from '@modules/pdf/application/create-solar-offer-pdf'
import { downloadSolarOfferPdf } from '@modules/pdf/application/download-solar-offer-pdf'
import { ParamsQuery } from '@modules/pdf/domain/offer.types'

type Callbacks = {
  onOfferPdfUrl: (fileUrl: string) => void
  onDownloadPdfError: () => void
}

export function downloadIndirectSolarOfferPdf() {
  return { execute }

  async function execute(
    {
      offerId,
      name,
      surname,
      address,
      salesUserId,
      salesUserChannel,
      salesUserName,
      tariff,
    }: ParamsQuery,
    { onOfferPdfUrl, onDownloadPdfError }: Callbacks
  ) {
    function onCreatedPdf(pdfId: string) {
      let errorsGettingPdf = 0
      const data = setInterval(async () => {
        function onPdfUrlError() {
          errorsGettingPdf++
          if (errorsGettingPdf >= 5) {
            clearInterval(data)
            onDownloadPdfError()
          }
        }

        function onPdfUrl(fileUrl: string) {
          errorsGettingPdf = 0
          clearInterval(data)
          onOfferPdfUrl(fileUrl)
        }

        await downloadSolarOfferPdf().execute(pdfId, {
          onPdfUrl,
          onPdfUrlError,
        })
      }, 2000)
    }

    function onCreatePdfError() {
      onDownloadPdfError()
    }

    await createSolarOfferPdf().execute(
      {
        offerId,
        name,
        surname,
        address,
        salesUserId,
        salesUserChannel,
        salesUserName,
        tariff,
      },
      {
        onCreatedPdf,
        onCreatePdfError,
      }
    )
  }
}
