
import CmbModal from '@components/CmbModal/CmbModal.vue'
export default {
  name: 'PublicHeader',
  components: { CmbModal },
  data() {
    return {
      phoneLink: 'tel:900670170',
      phoneText: '900 67 01 70',
    }
  },
}
