const CLEAR_UPDATE = (state) => {
  state.updateRequest.error = null
  state.updateRequest.success = false
}

const SET_UPDATE_ERROR = (state, error) => {
  state.updateRequest.error = error
}

const SET_UPDATE_SUCCESS = (state, success) => {
  state.updateRequest.success = success
}

const SET_UPDATE_LOADING = (state, loading) => {
  state.updateRequest.loading = loading
}

const CLEAR_VALIDATION = (state) => {
  state.validationRequest.error = null
  state.validationRequest.success = false
}
const SET_VALIDATION_ERROR = (state, error) => {
  state.validationRequest.error = error
}

const SET_VALIDATION_SUCCESS = (state, success) => {
  state.validationRequest.success = success
}

const SET_VALIDATION_LOADING = (state, loading) => {
  state.validationRequest.loading = loading
}

export default {
  CLEAR_UPDATE,
  SET_UPDATE_ERROR,
  SET_UPDATE_SUCCESS,
  SET_UPDATE_LOADING,
  CLEAR_VALIDATION,
  SET_VALIDATION_ERROR,
  SET_VALIDATION_SUCCESS,
  SET_VALIDATION_LOADING,
}
