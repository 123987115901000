import OfferService from '@infrastructure/offer.api'

export function handleGetOffer({ respondWithOffer, respondWithError }) {
  return { execute }

  async function execute(payload) {
    if (!payload.cups) {
      return
    }

    try {
      const offer = await OfferService.getOffer(payload)
      if (offer.installation.isEligible) {
        respondWithOffer(offer)
      } else {
        respondWithError('offer-error')
      }
    } catch (error) {
      respondWithError('api-error')
    }
  }
}
