import type { IDealRepository } from '@modules/deal/domain/deal.repository'
import type { UpdateDealRequest } from '@modules/deal/domain/stage.types'

import { solarSalesAxios } from '@infrastructure/apiService'

export const dealApiService: IDealRepository = {
  setKo: async (cups: string, reason: string) => {
    await solarSalesAxios.post(`${cups}/deal/ko`, {
      reason,
    })
    return Boolean(true)
  },

  getSolarStage: async (cups: string) => {
    const { data } = await solarSalesAxios.get(`${cups}/deal`)

    return data.stage
  },
  updateCups: async (payload: UpdateDealRequest): Promise<void> => {
    await solarSalesAxios.patch(`deal/${payload.oldCups}`, {
      new_cups: payload.newCups,
      lead_id: payload.leadId,
    })
  },
}
