import { solarOfferService } from '@modules/pdf/infrastructure/solar-offer.service'
import { ParamsQuery } from '@modules/pdf/domain/offer.types'

type Callbacks = {
  onCreatedPdf: (pdfId: string) => void
  onCreatePdfError: () => void
}

export function createSolarOfferPdf() {
  return { execute }

  async function execute(
    params: ParamsQuery,
    { onCreatedPdf, onCreatePdfError }: Callbacks
  ) {
    try {
      const pdfId = await solarOfferService.generateSolarOfferPdf(params)
      onCreatedPdf(pdfId)
    } catch (error) {
      onCreatePdfError()
    }
  }
}
