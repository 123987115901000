import OfferService from '@infrastructure/offer.api'

export function handleRecoverOffer({
  respondWithOffer,
  respondWithNoOffer,
  respondWithError,
}) {
  return { execute }

  async function execute({ cups }) {
    try {
      const offer = await OfferService.recoverOffer(cups)
      if (offer.installation.isEligible) {
        respondWithOffer(offer)
      } else {
        respondWithError('offer-error')
      }
    } catch (error) {
      error.response?.status === 404
        ? respondWithNoOffer()
        : respondWithError('api-error')
    }
  }
}
