const getCreateLeadByEmailParams = ({
  cups,
  email,
  phone,
  name,
  address,
  salesUsername,
  solarOfferId,
  fee,
  language,
  paymentMethod,
  product,
  productId,
  productPrices,
  powerByPeriod,
  isFinanced,
}) => {
  return {
    address,
    cups,
    email,
    phone,
    name,
    salesUsername,
    fee,
    paymentMethod,
    product,
    productId,
    solarOfferId,
    source: 'gimlet',
    language,
    businessUnit: 'solar',
    pricingOrigin: 'gimlet',
    productPrices,
    powerByPeriod,
    tags: tags(),
    query: query(),
  }

  function tags() {
    const tagFinancing = isFinanced
      ? 'visit-solar-offer-financed'
      : 'visit-solar-offer-cash'

    return ['visit-solar', tagFinancing]
  }

  function query() {
    return btoa(
      JSON.stringify({
        cups,
      })
    )
  }
}

export { getCreateLeadByEmailParams }
