export type updateAddressOnClipsParams = {
  cups: string
  postalCode: string
  locality: string
  street: string
}

export type ApiErrors = {
  404: string
  400: string
  500: string
}

export const API_ERRORS_MESSAGE = {
  404: 'update-address-not-found',
  400: 'update-address-param-request-wrong',
  422: 'update-address-param-request-wrong',
  500: 'api-error',
}
