import { IVisitInterestRepository } from '@modules/visit-interest/domain'
import { solarSalesAxios } from '@infrastructure/apiService'

export const visitInterestService: IVisitInterestRepository = {
  saveVisitInterest: async (payload) => {
    const params = {
      level: String(payload.interest),
    }
    const data = await solarSalesAxios.post(`${payload.cups}/interest`, params)
    return data
  },
}
