import { updateAddressService } from '@modules/update-address/infrastructure/update-address.service'
import {
  updateAddressOnClipsParams,
  ApiErrors,
  API_ERRORS_MESSAGE,
} from '@modules/update-address/domain/update-address.types'

type Callbacks = {
  onAddressUpdated: () => void
  onNetworkError: (errorMessage: string) => void
}
export function updateAddressOnClips() {
  return { execute }

  async function execute(
    { cups, postalCode, locality, street }: updateAddressOnClipsParams,
    { onAddressUpdated, onNetworkError }: Callbacks
  ) {
    try {
      await updateAddressService.updateAddressOnClips({
        cups,
        postalCode,
        locality,
        street,
      })
      onAddressUpdated()
    } catch (error) {
      const status = error.response?.status
      if (status) {
        onNetworkError(API_ERRORS_MESSAGE[status as keyof ApiErrors])
      }
    }
  }
}
