/* eslint-disable @typescript-eslint/no-empty-function */
import { handleIsOfferable } from '@modules/is-offerable/application/is-offerable'
import { getPotentialByCups } from '@modules/potential/application/get-potential-by-cups'

type Callbacks = {
  onSuccess: () => void
  onError: (error: string) => void
}

export function handleValidateUpdateCupsRequest() {
  return { execute }

  async function execute(cups: string, { onSuccess, onError }: Callbacks) {
    let errorMsg = ''

    await handleIsOfferable().execute(cups, {
      respondWithisOfferable: () => {},
      respondWithNoOfferable: () => {
        errorMsg = 'not-offerable'
      },
      respondWithError: () => {
        errorMsg = 'not-offerable'
      },
    })

    await getPotentialByCups().execute(
      { cups, isExternalChannel: false },
      {
        onPotential: () => {
          errorMsg = 'potential-already-exists'
        },
        onNoPotential: () => {},
        onExternalChannel: () => {},
        onNetworkError: () => {
          errorMsg = 'potential-already-exists'
        },
      }
    )

    if (errorMsg) {
      return onError(errorMsg)
    }
    onSuccess()
  }
}
