export function YearlyFinancingCondition({
  minQuota,
  TIN,
  CAP,
  gracePeriodMonths,
}) {
  return Object.freeze({
    minQuota,
    tin: TIN,
    cap: CAP,
    gracePeriodMonths,
  })
}
