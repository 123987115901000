import { Plugin } from '@nuxt/types'

type EventPayload = {
  label: string
  action: 'formSubmit' | 'search'
}

type GtmPayload = {
  event: 'gaEvent'
  eventLabel: string
  eventAction: EventPayload['action']
  eventCategory: 'SolarVisit'
}
function createGtmPayload({ label, action }: EventPayload): GtmPayload {
  return {
    event: 'gaEvent',
    eventLabel: label,
    eventAction: action,
    eventCategory: 'SolarVisit',
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $gtm: {
      push(payload: EventPayload): void
    }
  }
}

const gtmOverwrite: Plugin = ({ app }, inject) => {
  if (process.server || !app.$gtm) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return inject('gtm', { push: () => {} })
  }
  const { push } = app.$gtm
  inject(
    'gtm',
    Object.assign(app.$gtm, {
      push(obj: EventPayload) {
        const payload = createGtmPayload(obj)
        push.call(app.$gtm, payload)
      },
    })
  )
}

export default gtmOverwrite
