export const initialState = {
  cups: '',
  client: {
    id: null,
    name: null,
    email: null,
    phoneNumber: null,
    // Temporary change: currentFee calculated with solar margin
    electricityProduct: {
      name: 'UN Precio Clásico',
      id: 'CL_1P_20TD',
    },
  },
  supplyPoint: null,
  electricityContractId: '',
  offerStatus: '',
  installationStage: '',
  overwrittenCups: false,
}

export default () => ({ ...initialState })
