import FinancingService from '@infrastructure/financing.api'

export function handleGetFinancingConditions({
  respondWithSuccess,
  respondWithError,
}) {
  return { execute }

  async function execute() {
    try {
      const financingConditions =
        await FinancingService.getFinancingConditions()
      respondWithSuccess(financingConditions)
    } catch (error) {
      respondWithError(error)
    }
  }
}
