import {
  BatteryTemplate,
  EvChargerTemplate,
} from '@modules/assets-catalog/domain/assets-catalog.types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatBatteriesCatalog = (payload: any): BatteryTemplate[] => {
  return payload.results.map((item: BatteryTemplate) => ({
    id: item.id,
    brand: item.brand,
    model: item.model,
    type: 'battery',
    maxChargingPower: item.maxChargingPower,
    capacity: item.capacity,
    priceWithTaxes: item.priceWithTaxes,
  }))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatEvChargersCatalog = (payload: any): EvChargerTemplate[] => {
  return payload.results.map((item: EvChargerTemplate) => ({
    id: item.id,
    brand: item.brand,
    model: item.model,
    type: 'evCharger',
    maxChargingPower: item.maxChargingPower,
    priceWithTaxes: item.priceWithTaxes,
  }))
}

export const parseInstallationType = (installationType: number): string => {
  const types: { [key: number]: string } = {
    1: 'monophasic',
    3: 'triphasic',
  }

  return types[installationType]
}
