import { initialState } from './state'

const SET_CUPS = (state, cups) => {
  state.cups = cups
}

const SET_SUPPLYPOINT = (state, supplyPoint) => {
  state.supplyPoint = supplyPoint
}

const SET_SUPPLYPOINT_ADDRESS = (state, address) => {
  state.supplyPoint.address = address
}

const SET_CLIENT = (
  state,
  { id, clientFullName, email, language, phoneNumber, productName, productId }
) => {
  state.client.id = id
  state.client.name = clientFullName
  state.client.email = email
  state.client.phoneNumber = phoneNumber
  state.client.language = language
  // Temporary change: currentFee calculated with solar margin
  state.client.electricityProduct = {
    name: productName || state.client.electricityProduct.name,
    id: productId || state.client.electricityProduct.id,
  }
}

const RESET_STATE = (state) => {
  Object.assign(state, initialState)
}

const SET_ELECTRICITY_CONTRACT_ID = (state, contractId) => {
  state.electricityContractId = contractId
}

const SET_DEAL_STAGE = (state, status) => {
  state.offerStatus = status
}

const SET_INSTALLATION_STAGE = (state, stage) => {
  state.installationStage = stage
}

const SET_OVERWRITTEN_CUPS = (state, overwrittenCups) => {
  state.overwrittenCups = overwrittenCups
}

export default {
  SET_CUPS,
  SET_SUPPLYPOINT,
  SET_CLIENT,
  RESET_STATE,
  SET_ELECTRICITY_CONTRACT_ID,
  SET_DEAL_STAGE,
  SET_INSTALLATION_STAGE,
  SET_SUPPLYPOINT_ADDRESS,
  SET_OVERWRITTEN_CUPS,
}
