
import PublicHeader from '@components/PublicHeader'
import PublicFooter from '@components/PublicFooter'
import TheStepper from '@components/TheStepper/TheStepper.vue'
export default {
  name: 'LayoutPublic',

  components: {
    TheStepper,
    PublicHeader,
    PublicFooter,
  },
}
