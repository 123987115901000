import formUrlencoded from 'form-urlencoded'
import { getCreateLeadByEmailParams } from './leads.utils'
import { coreAxios } from '@infrastructure/apiService'

function factory(service) {
  return {
    async createLeadByEmail(payload) {
      const params = getCreateLeadByEmailParams(payload)

      try {
        await service.post(
          `/private/leads/email/${params.email}`,
          formUrlencoded(params, { ignorenull: true })
        )
      } catch (error) {
        throw mapErrors(error)
      }

      function mapErrors(error) {
        if (error?.response?.status !== 400) return null

        return Object.entries(error.response.data.errors).map(
          ([field, value]) => `${field}: ${value.message[0]}`
        )
      }
    },
  }
}

export default factory(coreAxios)
