import { YearlyFinancingCondition } from '@domain/Financing/YearlyFinancingCondition'
export function FinancingCondition({
  id,
  name,
  title,
  minLoanYears,
  maxLoanYears,
  yearlyParameters,
}) {
  function getYearlyParameters() {
    Object.keys(yearlyParameters).forEach((key) => {
      yearlyParameters[key] = YearlyFinancingCondition(yearlyParameters[key])
    })
    return yearlyParameters
  }

  return Object.freeze({
    id,
    name,
    title,
    minLoanYears,
    maxLoanYears,
    yearlyParameters: getYearlyParameters(),
  })
}
