import { visitCheckService } from '@modules/visit-check/infrastructure/visit-check.service'

type Callbacks = {
  respondWithSuccess: () => void
  respondWithError: () => void
}

export function handleSaveVisitCheck() {
  return { execute }

  async function execute(
    cups: string,
    { respondWithSuccess, respondWithError }: Callbacks
  ) {
    try {
      await visitCheckService.saveVisitCheck(cups)
      respondWithSuccess()
    } catch (error) {
      respondWithError()
    }
  }
}
