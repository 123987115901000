import { Plugin } from '@nuxt/types'

const initGoogleMaps: Plugin = (_, inject) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { maps } = (window as any).google

  if (!maps) return
  inject('gmaps', maps)
}
export default initGoogleMaps
