const SET_CURRENT_STEP = (state, value) => {
  state.currentStep = value
}

const SET_TOTAL_STEPS = (state, value) => {
  state.totalSteps = value
}

export default {
  SET_CURRENT_STEP,
  SET_TOTAL_STEPS,
}
