import { Plugin } from '@nuxt/types'
import * as configcat from 'configcat-js'
import { PollingMode } from 'configcat-js'

declare module 'vue/types/vue' {
  interface Vue {
    $featureFlag(featureName: string, defaultValue: boolean): boolean
  }
}

const initConfigCat: Plugin = ({ $config, store }, inject) => {
  const configCatClient = configcat.getClient(
    $config.configCatApiKey,
    PollingMode.ManualPoll
  )

  configCatClient.forceRefreshAsync().then(async () => {
    const email = store.getters['user/username']
    const userObject = new configcat.User(
      /* identifier: */ email,
      /*      email: */ email
    )

    const settingValues = await configCatClient.getAllValuesAsync(userObject)
    store.commit('featureFlag/SET_FEATURE_FLAGS', settingValues)
  })

  inject('featureFlag', (featureName: string, defaultValue: boolean) => {
    const featureFlags = store.getters['featureFlag/featureFlags']

    if (!featureFlags || !featureFlags.find) {
      return defaultValue
    }

    const featureFlag = featureFlags.find(
      (v: { settingKey: string; settingValue: string }) =>
        v.settingKey === featureName
    )

    if (featureFlag) {
      return featureFlag.settingValue
    }

    return defaultValue
  })
}

export default initConfigCat
