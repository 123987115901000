export const getUpdateOfferParams = ({
  offer,
  installationTax,
  financingConditionsId,
}) => ({
  capex: offer.capex,
  totalValue: offer.totalValue,
  installationTax,
  investment: offer.initialInvestment,
  loanYears: offer.financingData.loanYears || 0,
  grossCustomerSavingsWithTaxes: offer.grossCustomerSavingsWithTaxes,
  financingConditionsId:
    financingConditionsId === 'cash' ? null : financingConditionsId,
  monthlyLoanWithTaxes: offer.financingData.monthlyLoanWithTaxes || 0,
})
