import { IUpdateAddressRepository } from '@modules/update-address/domain'
import { solarSalesAxios } from '@infrastructure/apiService'
export const updateAddressService: IUpdateAddressRepository = {
  updateAddressOnClips: async ({ cups, postalCode, locality, street }) => {
    const { data } = await solarSalesAxios.put(`/${cups}/address`, {
      postalCode,
      locality,
      street,
    })

    return data
  },
}
