
import { mapGetters } from 'vuex'

export default {
  name: 'TheStepper',
  computed: {
    ...mapGetters('wizard', ['currentStep', 'totalSteps']),

    stepsProgress() {
      return `width: ${(this.currentStep / this.totalSteps) * 100}%;`
    },
  },
}
