import createAuthRefreshInterceptor from 'axios-auth-refresh'
import Cookie from 'js-cookie'
import get from 'lodash/get'

import publicRuntimeConfig from '../../public-runtime.config'
import { coreAxios } from '@infrastructure/apiService'

const REFRESH_URI = '/token/refresh'
const COOKIE_REFRESH_TOKEN = 'holaluz_refresh_token'

const refreshAuthLogic = () => (request) => {
  // Only try to refresh the token if it has expired, not on each 401 error
  const errorMessage = get(request, 'response.data.message', '')
  if (!/jwt/i.test(errorMessage)) {
    return Promise.reject(request)
  }

  return coreAxios
    .post(
      REFRESH_URI,
      { refresh_token: Cookie.get(COOKIE_REFRESH_TOKEN) },
      { skipAuthRefresh: true }
    )
    .then(({ data }) => {
      request.response.config.headers.Authorization = `Bearer ${data.token}`
      return Promise.resolve()
    })
    .catch(() => {
      window.location.href = `${publicRuntimeConfig.barman}?project=gimlet&isDevelopment=${publicRuntimeConfig.isDevelopment}&localPort=${window.location.port}`
    })
}

export default function refreshToken() {
  createAuthRefreshInterceptor(coreAxios, refreshAuthLogic())
}
