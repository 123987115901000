import { IPotentialRepository } from '@modules/potential/domain'
import { coreAxios } from '@infrastructure/apiService'

export const potentialService: IPotentialRepository = {
  getPotentialByCups: async (cups: string) => {
    const { data: potentials } = await coreAxios.get(
      `/private/potentials/${cups}`
    )

    if (!potentials.length) {
      throw new Error('no potentials found')
    }

    const { id, clientFullName, email, phoneNumber, language } =
      potentials[potentials.length - 1]

    return {
      id,
      clientFullName,
      email,
      phoneNumber,
      language,
    }
  },
}
