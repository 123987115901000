import camelCase from 'lodash/camelCase'
import isPlainObject from 'lodash/isPlainObject'

function transformKeys(obj, callback) {
  if (!(typeof callback === 'function')) {
    throw new TypeError(
      `Callback function must be a function, ${typeof callback} given`
    )
  }

  if (Array.isArray(obj)) {
    return obj.map((o) => transformKeys(o, callback))
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [callback(key)]: transformKeys(obj[key], callback),
      }),
      {}
    )
  }

  return obj
}

function camelizeKeys(obj) {
  return transformKeys(obj, camelCase)
}

export { camelizeKeys, transformKeys }
