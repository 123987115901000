import { Contract } from '@domain/Contract'
import { solarSalesAxios } from '@infrastructure/apiService'

function factory(service) {
  return {
    async getContract(cups) {
      const { data } = await service.get(`/${cups}/contract-status`)

      return Contract(data)
    },
    async submitManualSignature(cups, file) {
      const formData = new FormData()
      formData.append('file', file)
      await service.post(`/${cups}/contract/manual-signature`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  }
}

export default factory(solarSalesAxios)
