import { IElectricityContractRepository } from '@modules/electricity-contract/domain'
import { coreAxios } from '@infrastructure/apiService'

export const electricityContractService: IElectricityContractRepository = {
  hasElectricityContract: async (cups: string) => {
    try {
      const { data } = await coreAxios.get('/contracts', { params: { cups } })

      return Boolean(data)
    } catch (error) {
      // 409 means there's a conflict with the given CUPS because it already
      // exists an associated contract.
      if (error.status.response === 409) {
        return false
      }

      throw error
    }
  },
  getContractByCups: async (cups) => {
    const { data } = await coreAxios.get(`/private/contract/${cups}/status`)

    const { contractId: id, status, productId, solarProjectId } = data

    return {
      id,
      status,
      productId,
      solarProjectId,
    }
  },
}
