export function FinancingData({
  financingConditionsId,
  totalLoanAmount,
  tae,
  gracePeriodMonths,
  tin,
  cap,
  minQuota,
  monthlyLoanWithTaxes,
  loanYears,
  maxCap,
  maxFinancingAmount,
}) {
  return Object.freeze({
    financingConditionsId,
    totalLoanAmount,
    tae,
    gracePeriodMonths,
    tin,
    cap,
    minQuota,
    monthlyLoanWithTaxes,
    loanYears,
    maxCap,
    maxFinancingAmount,
  })
}
