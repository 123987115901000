import OfferSpecsService from '@infrastructure/offer-specs.api'
import { specsResponseRequestStatus } from '@domain/OfferSpecs/OfferSpecs'

export function handleGetOfferSpecs({
  respondWithSpecs,
  respondWithNoOffer,
  respondWithError,
  respondWithExpiredOffer,
}) {
  return { execute }

  async function execute(cups) {
    try {
      const specs = await OfferSpecsService.getOfferSpecs(cups)

      respondWithSpecs(specs)
    } catch (error) {
      if (error.response.status === 404) {
        error.response.data?.code === specsResponseRequestStatus.expired
          ? respondWithExpiredOffer()
          : respondWithNoOffer()
        return
      }
      respondWithError()
    }
  }
}
