const setCurrentStep = ({ commit }, currentStep) => {
  commit('SET_CURRENT_STEP', currentStep)
}
const setTotalSteps = ({ commit }, totalSteps) => {
  commit('SET_TOTAL_STEPS', totalSteps)
}

export default {
  setCurrentStep,
  setTotalSteps,
}
