import { defaultSelectedOffer } from '@modules/offer/domain/offer.const'

export const initialState = {
  specsSubmitted: false,
  specsError: null,
  offerError: null,
  offerResult: null,
  radiationError: null,
  specsFormValidation: {},
  specs: {},
  specsResult: null,
  preOffers: {},
  finalOffers: {},
  simulatedOffer: {},
  activeOfferContext: 'preOffers',
  selectedOffer: defaultSelectedOffer,
  installationTax: '',
  taxType: '',
  dueDate: '',
  financingConditions: [],
  selectedFinancingCondition: 'cash',
  offerPdfError: false,
  offerPdfNotFound: false,
  loadingIndirectSolarOfferPdf: false,
  errorDownloadIndirectSolarOfferPdf: false,
  showIndirectDownloadPdfAlert: false,
  isVerified: '',
  isOfferable: null,
  isOfferableError: false,
  isSimulatedOfferLoading: false,
  clientType: '',
  hasDoneEnergyReform: '',
  assetsCatalog: [],
  loadingAssetsCatalog: false,
  errorAssetsCatalog: false,
  contract: null,
  contractError: null,
  isSimulationOfferHidden: false,
}

export default () => ({ ...initialState })
