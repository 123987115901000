import { camelizeKeys } from '@ui/utils/object'
import { isBase64 } from '@ui/utils/base64'

const parseEncodedObject = (obj) => JSON.parse(atob(obj))

const camelizeResponseData = (response) => {
  if (isBase64(response.data)) {
    response.data = parseEncodedObject(response.data)
  }

  response.data = camelizeKeys(response.data)

  return response
}

export default (instance) =>
  instance.interceptors.response.use(camelizeResponseData, undefined)
