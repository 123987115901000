import { ISolarOfferRepository } from '@modules/pdf/domain'
import { solarSalesAxios } from '@infrastructure/apiService'

export const solarOfferService: ISolarOfferRepository = {
  getSolarOfferPdf: async (pdfId) => {
    const { data } = await solarSalesAxios.get(`offer/${pdfId}/pdf`)
    return String(data.fileUrl)
  },
  generateSolarOfferPdf: async (params) => {
    const { offerId, ...queryParams } = params
    const { data } = await solarSalesAxios.post(
      `offer/indirect/${offerId}/pdf`,
      queryParams
    )
    return String(data.id)
  },
}
