const inRange = (number: number, min: number, max: number): boolean => {
  return number >= min && number <= max
}

const formatPercent = (number: number): string => {
  return number.toLocaleString('en-EN', {
    style: 'percent',
  })
}

function formatDecimal(num: number, options = {}) {
  return Number(num).toLocaleString('de', {
    maximumFractionDigits: 2,
    ...options,
  })
}

export { inRange, formatPercent, formatDecimal }
