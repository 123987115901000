export const initialState = {
  updateRequest: {
    loading: false,
    success: false,
    error: null,
  },
  validationRequest: {
    loading: false,
    success: false,
    error: null,
  },
}

export default () => ({ ...initialState })
