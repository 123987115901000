import LeadsService from '@infrastructure/leads.api'
import OfferService from '@infrastructure/offer.api'

export function handleCreateLeadByEmail({
  respondWithSuccess,
  respondWithError,
}) {
  return { execute }

  async function execute(payload) {
    try {
      await OfferService.updateOffer(payload)
      await LeadsService.createLeadByEmail(payload)

      respondWithSuccess()
    } catch (error) {
      respondWithError(error)
    }
  }
}
