import { isOfferableService } from '@modules/is-offerable/infrastructure/is-offerable.service'

type Callbacks = {
  respondWithisOfferable: () => void
  respondWithNoOfferable: () => void
  respondWithError: () => void
}

export function handleIsOfferable() {
  return { execute }

  async function execute(
    cups: string,
    {
      respondWithisOfferable,
      respondWithNoOfferable,
      respondWithError,
    }: Callbacks
  ) {
    try {
      const isOfferable = await isOfferableService.isOfferable(cups)

      if (isOfferable) {
        respondWithisOfferable()
      } else {
        respondWithNoOfferable()
      }
    } catch (error) {
      respondWithError()
    }
  }
}
