import {
  latitudeValues as latitude,
  longitudeValues as longitude,
} from '@domain/Coordinates/Coordinates'
import { azimuth, tilt } from '@domain/OfferSpecs/OfferSpecs'
import RadiationService from '@infrastructure/radiation.api'
import { inRange } from '@ui/utils/number'

export function handleGetRadiation({ respondWithSuccess, respondWithError }) {
  return { execute }

  function isEmpty(value) {
    return value === null || value === ''
  }

  async function execute(radiation, specs) {
    if (isEmpty(specs.azimuth) || isEmpty(specs.tilt)) {
      return
    }

    const validations = {
      latitude,
      longitude,
      azimuth,
      tilt,
    }
    const areValuesInRange = []
    for (const key in specs) {
      areValuesInRange.push(
        inRange(Number(specs[key]), validations[key].min, validations[key].max)
      )
    }
    const areValidValues = areValuesInRange.every((el) => el === true)

    if (areValidValues) {
      try {
        const value = await RadiationService.getRadiation(specs)
        respondWithSuccess({ [radiation]: value.radiation })
      } catch (error) {
        respondWithError()
      }
    }
  }
}
