import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { getUpsertOfferSpecsParams } from './offer-specs.utils'
import { solarProductAxios } from '@infrastructure/apiService'
import { OfferSpecs } from '@domain/OfferSpecs'

function factory(service) {
  return {
    async getOfferSpecs(cups) {
      const { data } = await service.get(`/private/${cups}/offer-data`)

      return OfferSpecs(data)
    },

    async upsertOfferSpecs(payload) {
      const params = getUpsertOfferSpecsParams(payload)
      await service.post(
        `private/upsert-verified-offer-data`,
        JSON.stringify(omitBy(params, isNil))
      )
    },
  }
}

export default factory(solarProductAxios)
