import { solarSalesAxios } from '@infrastructure/apiService'
import { IVerifiedOfferRepository } from '@modules/offer/domain/verified-offer.repository'
import { VerifiedOffer } from '@modules/offer/domain/verified-offer.types'

export const verifiedOfferService: IVerifiedOfferRepository = {
  listVerifiedOffers: async (cups): Promise<Array<VerifiedOffer>> => {
    const { data } = await solarSalesAxios.get(
      `${cups}/offers/ready-to-contract`
    )
    return data
  },
}
