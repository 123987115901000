import {
  Cover,
  Obstacle,
  Panel,
  Bounds,
  Roof,
  Distribution,
} from '@modules/drawing/domain/drawing.types'

function getCovers(covers: { [key: string]: Cover }) {
  return Object.values(covers).reduce(
    (obj: Record<string, Cover>, item: Cover, idx: number) => ({
      ...obj,
      [`cover${idx + 1}`]: {
        coords: item.coords,
        tilt: item.tilt,
        heading: item.heading,
        pyramidal: item.pyramidal,
      },
    }),
    {}
  )
}

function getObstacles(obstacles: { [key: string]: Obstacle }) {
  return Object.values(obstacles).reduce(
    (obj: Record<string, Obstacle>, item: Obstacle, idx: number) => ({
      ...obj,
      [`obs${idx + 1}`]: {
        lat: item.lat,
        lng: item.lng,
        height: item.height,
      },
    }),
    {}
  )
}

function getPanels(panels: { [key: string]: Panel }) {
  return Object.values(panels).reduce(
    (obj: Record<string, Panel>, item: Panel, idx: number) => ({
      ...obj,
      [`pv${idx + 1}`]: item,
    }),
    {}
  )
}

function getBounds(bounds: Bounds) {
  return {
    ne: { lat: bounds.north, lng: bounds.east },
    nw: { lat: bounds.north, lng: bounds.west },
    se: { lat: bounds.south, lng: bounds.east },
    sw: { lat: bounds.south, lng: bounds.west },
  }
}

const getSendCoversParams = (params: Roof) => {
  return {
    cups: params.cups,
    num_pv: params.pv,
    covers: getCovers(params.covers),
    bounds: getBounds(params.bounds),
    obstacles: getObstacles(params.obstacles),
  }
}

const getSendPanelsParams = (params: Distribution) => {
  return {
    cups: params.cups,
    num_pv: params.pv,
    covers: getCovers(params.covers),
    obstacles: getObstacles(params.obstacles),
    center: params.center,
    panels: getPanels(params.panels),
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatPanels = (panels: any): Panel[] => {
  return Object.values(panels)
}

export { getSendCoversParams, getSendPanelsParams, formatPanels }
