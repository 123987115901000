import SolarSalesService from '@infrastructure/offer-pdf.api'
import OfferService from '@infrastructure/offer.api'

const fileUrl = 'fileUrl'

export default function ({
  responseWithOfferUrl,
  responseWithError,
  responseWithNotFound,
}) {
  return { execute }

  async function execute(payload) {
    try {
      await OfferService.updateOffer(payload)
      const data = await SolarSalesService.offerPdf(payload.cups)
      responseWithOfferUrl(data[fileUrl])
    } catch (error) {
      if (error.response?.status === 404) {
        responseWithNotFound()
        return
      }
      responseWithError()
    }
  }
}
