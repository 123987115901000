import { VisitInterest } from '../domain/visit-interest.types'
import { visitInterestService } from '@modules/visit-interest/infrastructure/visit-interest.service'

type Callbacks = {
  respondWithSuccess: () => void
  respondWithError: () => void
}

export function handleSaveVisitInterest() {
  return { execute }

  async function execute(
    payload: VisitInterest,
    { respondWithSuccess, respondWithError }: Callbacks
  ) {
    try {
      await visitInterestService.saveVisitInterest(payload)
      respondWithSuccess()
    } catch (error) {
      respondWithError()
    }
  }
}
