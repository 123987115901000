const CLEAR = (state) => {
  state.error = null
  state.success = false
}

const SET_ERROR = (state, error) => {
  state.error = error
}

const SET_SUCCESS = (state, success) => {
  state.success = success
}

const SET_LOADING = (state, loading) => {
  state.loading = loading
}

export default {
  CLEAR,
  SET_ERROR,
  SET_SUCCESS,
  SET_LOADING,
}
