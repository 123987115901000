import formUrlencoded from 'form-urlencoded'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { getUpdateOfferParams } from './offer.utils'
import { getUpsertOfferSpecsParams } from './offer-specs.utils'
import { solarProductAxios } from '@infrastructure/apiService'
import { Installation } from '@domain/Installation'
import { Offer, TYPE_OF_OFFER } from '@domain/Offer'

function modelOffer(offer) {
  return {
    installation: Installation({
      cups: offer.cups,
      eligible: offer.eligible,
      coordinates: offer.coordinates,
      taxType: offer.taxType,
      installationTax: offer.installationTax,
      reason: offer.reason,
      currentInvoice: offer.currentInvoice,
      dueDate: offer.dueDate,
      informationSource: offer.informationSource,
      clientType: offer?.clientType,
      hasDoneEnergyReform: offer?.hasDoneEnergyReform,
    }),
    defaultOffers: {
      standard: Offer({ ...offer.offers?.[TYPE_OF_OFFER.STANDARD] }),
      recommended: Offer({ ...offer.offers?.[TYPE_OF_OFFER.RECOMMENDED] }),
      ...(offer.offers?.[TYPE_OF_OFFER.CUSTOM] && {
        custom: Offer(offer.offers[TYPE_OF_OFFER.CUSTOM]),
      }),
      ...(offer.offer && {
        custom: Offer(offer.offer),
      }),
    },
  }
}

function factory(service) {
  return {
    async getOffer(params) {
      const payload = {
        latitude: params.latitude,
        longitude: params.longitude,
        forceNewOffer: params.forceNewOffer,
        clientType: params.clientType,
        hasDoneEnergyReform: params.hasDoneEnergyReform,
      }

      const { data } = await service.get(`/private/${params.cups}/offer`, {
        params: omitBy(payload, isNil),
        timeout: 70000,
      })

      return modelOffer(data)
    },

    async recoverOffer(cups) {
      const { data } = await service.get(`/private/${cups}/recoveroffer`)

      return modelOffer(data)
    },

    async updateOffer(payload) {
      const params = getUpdateOfferParams(payload)

      await service.put(
        `private/offer/${payload.offer.id}`,
        formUrlencoded(omitBy(params, isNil))
      )
    },

    async getSimulatedVerifiedOffer(params) {
      const { cups } = params
      const { data } = await solarProductAxios.post(
        `/private/${cups}/offer/simulate-verified`,
        getUpsertOfferSpecsParams(params)
      )
      return Offer(data.offer)
    },
  }
}

export default factory(solarProductAxios)
