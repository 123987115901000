import OfferSpecsService from '@infrastructure/offer-specs.api'
import { specsErrors } from '@domain/OfferSpecs'

export function handleUpsertOfferSpecs({
  respondWithSuccess,
  respondWithError,
}) {
  return { execute }

  function getQueryParams(specs) {
    const { panels2, shadedPanels2, azimuth2, tilt2 } = specs
    // temporary patch until covers refactoring
    if (
      panels2 === 0 &&
      shadedPanels2 === 0 &&
      azimuth2 === '' &&
      tilt2 === ''
    ) {
      return { ...specs, panels2: '', shadedPanels2: '' }
    }
    return specs
  }

  async function execute(specs) {
    const queryParams = getQueryParams(specs)

    try {
      await OfferSpecsService.upsertOfferSpecs(queryParams)
      respondWithSuccess(specs)
    } catch (error) {
      respondWithError(specsErrors.api)
    }
  }
}
