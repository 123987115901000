import { Coordinates } from '@domain/Coordinates'

export const installationType = [1, 3]

export const tension = [220, 230, 380, 400]

export const extraAdditionalCost = {
  min: 0,
}

export const panels = {
  min: (installationType, panelsModel) => {
    if (panelsModel === 'all-black') {
      return installationType === 3 ? 6 : 4
    }
    return installationType === 3 ? 5 : 3
  },
  max: (installationType, panelsModel) => {
    if (panelsModel === 'all-black') {
      return installationType === 3 ? 42 : 28
    }
    return installationType === 3 ? 34 : 22
  },
}

export const shadedPanels = {
  min: 0,
  max: (totalPanels) => totalPanels,
}

export const azimuth = {
  min: 0,
  max: 359,
  minForShadedPanels: 90,
  maxForShadedPanels: 270,
}

export const tilt = {
  min: 0,
  max: 75,
  coversTiltMismatch: (a, b) => (a < 5 && a !== '' && b !== '' ? b < 4 : true),
}

export const specsSource = {
  VERIFIED_OFFER_DATA: 'VERIFIED_OFFER_DATA',
  OFFER: 'OFFER',
}

export const specsErrors = {
  api: 'api-error',
}

export const specsResponseRequestStatus = {
  expired: 'EXPIRED',
  notFound: 'NOT_FOUND',
}

export const OfferSpecs = ({
  cups,
  lat,
  long,
  panels1,
  shadedPanels1,
  azimuth1,
  radiation1,
  tilt1,
  panels2,
  shadedPanels2,
  azimuth2,
  radiation2,
  tilt2,
  extraAdditionalCost,
  tension,
  currentInvoice,
  dataSource,
  sharedCups,
  assets,
  discounts,
  panelsModel,
  wantsEnergyEfficiencyCertificate,
  promotions,
}) => {
  const areSpecsFromAVerifiedOffer = isDataSourceFromAVerifiedOffer()
  return Object.freeze({
    cups,
    extraAdditionalCost,
    currentInvoice,
    coordinates: Coordinates({ latitude: lat, longitude: long }),
    tension: tension ? getTension() : '',
    installationType: tension ? getInstallationType() : '',
    panels1: areSpecsFromAVerifiedOffer ? parseInt(panels1) : 0,
    shadedPanels1:
      areSpecsFromAVerifiedOffer && shadedPanels1 ? parseInt(shadedPanels1) : 0,
    azimuth1: areSpecsFromAVerifiedOffer ? azimuth1 : '',
    tilt1: areSpecsFromAVerifiedOffer ? tilt1 : '',
    radiation1: areSpecsFromAVerifiedOffer ? radiation1 : '',
    panels2: areSpecsFromAVerifiedOffer && panels2 ? parseInt(panels2) : 0,
    shadedPanels2:
      areSpecsFromAVerifiedOffer && shadedPanels2 ? parseInt(shadedPanels2) : 0,
    azimuth2:
      areSpecsFromAVerifiedOffer && azimuth2 !== undefined ? azimuth2 : '',
    tilt2: areSpecsFromAVerifiedOffer && tilt2 !== undefined ? tilt2 : '',
    radiation2:
      areSpecsFromAVerifiedOffer && radiation2 !== undefined ? radiation2 : '',
    verifiedOfferData: areSpecsFromAVerifiedOffer,
    sharedCups: getSharedCups(),
    assets: getAssets(),
    discounts: getDiscounts(),
    panelsModel: panelsModel !== undefined ? panelsModel : 'silver',
    wantsEnergyEfficiencyCertificate,
    promotions,
  })

  function getTension() {
    return tension.split(/[x/?]/)[1]
  }

  function getInstallationType() {
    return tension.charAt(0)
  }

  function isDataSourceFromAVerifiedOffer() {
    return dataSource === specsSource.VERIFIED_OFFER_DATA
  }

  function getSharedCups() {
    return sharedCups
      ? sharedCups.reduce(
          (obj, item) => ({
            ...obj,
            [item.cups]: {
              monthlyInvoice: item.monthlyInvoice,
            },
          }),
          {}
        )
      : {}
  }

  function getAssets() {
    return assets
      ? assets.reduce(
          (obj, item) => ({
            ...obj,
            [item.id]: {
              ...item,
            },
          }),
          {}
        )
      : {}
  }

  function getDiscounts() {
    let result = {
      battery: 0,
      certificate: 0,
      evCharger: 0,
      panels: 0,
    }
    if (discounts && Object.keys(discounts).length > 0) {
      result = { ...result, ...discounts }
    }
    return result
  }
}

export const visitInterest = [1, 2, 3, 4, 5]
