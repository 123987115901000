import { IVisitCheckRepository } from '@modules/visit-check/domain'
import { solarSalesAxios } from '@infrastructure/apiService'

export const visitCheckService: IVisitCheckRepository = {
  saveVisitCheck: async (cups) => {
    const now = new Date().toISOString()
    const params = {
      moment: `${now.slice(0, 10)} ${now.slice(11, 19)}`,
    }
    const data = await solarSalesAxios.post(`${cups}/visit/check`, params)
    return data
  },
}
