import { dealApiService } from '@modules/deal/infrastructure/deal.api.service'

type Callbacks = {
  respondWithSuccess: () => void
  respondWithError: () => void
}

export function handleSetKo() {
  return { execute }

  async function execute(
    cups: string,
    reason: string,
    { respondWithSuccess, respondWithError }: Callbacks
  ) {
    try {
      await dealApiService.setKo(cups, reason)
      respondWithSuccess()
    } catch (error) {
      respondWithError()
    }
  }
}
