import { drawingService } from '@modules/drawing/infrastructure/drawing.service'
import { Panel, Roof } from '@modules/drawing/domain/drawing.types'

type Callbacks = {
  onSuccess: (panels: Panel[]) => void
  onError: () => void
}

export function handleSendCovers() {
  return { execute }

  async function execute(payload: Roof, { onSuccess, onError }: Callbacks) {
    try {
      const data = await drawingService.sendCovers(payload)
      data.length ? onSuccess(data) : onError()
    } catch {
      onError()
    }
  }
}
