import { solarProductAxios } from '@infrastructure/apiService'

function factory(service) {
  return {
    async getRadiation(payload) {
      const { latitude, longitude, azimuth, tilt } = payload
      const { data } = await service.get(
        `/private/radiation?latitude=${latitude}&longitude=${longitude}&azimuth=${azimuth}&tilt=${tilt}`
      )

      return data
    },
  }
}

export default factory(solarProductAxios)
