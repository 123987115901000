export const enum DEAL_STAGE {
  PRE_OFFER = 'pre-offer',
  FINAL_OFFER = 'final-offer',
  CONTRACT_SENT = 'contract-sent',
  SIGNED = 'signed',
  INSTALLED = 'installed',
  KO = 'ko',
}

export const MAPPED_STAGE = {
  preOffer: DEAL_STAGE.PRE_OFFER,
  visitScheduled: DEAL_STAGE.PRE_OFFER,
  visitDone: DEAL_STAGE.PRE_OFFER,
  verifiedOffer: DEAL_STAGE.FINAL_OFFER,
  projectPendingValidation: DEAL_STAGE.FINAL_OFFER,
  solarContractSent: DEAL_STAGE.CONTRACT_SENT,
  pendingFinancing: DEAL_STAGE.CONTRACT_SENT,
  withdrawal: DEAL_STAGE.SIGNED,
  solarContractSigned: DEAL_STAGE.SIGNED,
  techRevision: DEAL_STAGE.SIGNED,
  projectValidated: DEAL_STAGE.SIGNED,
  deliveryPendingPermissions: DEAL_STAGE.INSTALLED,
  deliveryPendingPayment: DEAL_STAGE.INSTALLED,
  deliveryPendingInstallationScheduling: DEAL_STAGE.INSTALLED,
  deliveryInstallationInProgress: DEAL_STAGE.INSTALLED,
  deliveryInstallationScheduled: DEAL_STAGE.INSTALLED,
  deliveryInstallationDone: DEAL_STAGE.INSTALLED,
  ko: DEAL_STAGE.KO,
  deliveryKo: DEAL_STAGE.KO,
}

export const MAPPED_INSTALLATION_STAGE = {
  deliveryPendingPermissions: 'pending-permissions',
  deliveryPendingPayment: 'pending-payment',
  deliveryPendingInstallationScheduling: 'pending-installation-scheduling',
  deliveryInstallationScheduled: 'installation-scheduled',
  deliveryInstallationInProgress: 'installation-in-progress',
  deliveryInstallationDone: 'installation-done',
}

export type SolarStage = keyof typeof MAPPED_STAGE
export type InstallationStageKeys = keyof typeof MAPPED_INSTALLATION_STAGE

export type UpdateDealRequest = {
  newCups: string
  oldCups: string
  leadId: string
}
