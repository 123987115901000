import { cups } from '@modules/cups/domain/cups'

type Parameters = {
  cups: string
}

type Callbacks = {
  onValidCups: () => void
  onInvalidCups: () => void
}

export function checkIfCupsIsValid() {
  return { execute }

  function execute(
    { cups: rawCups }: Parameters,
    { onValidCups, onInvalidCups }: Callbacks
  ) {
    cups(rawCups).isValid ? onValidCups() : onInvalidCups()
  }
}
