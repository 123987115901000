import ContractsService from '@infrastructure/contracts.api'

type Callbacks = {
  onSubmitted: () => void
  onError: () => void
}

export function handleSubmitManualSignature({
  onSubmitted,
  onError,
}: Callbacks) {
  return { execute }
  async function execute(cups: string, file: File) {
    try {
      await ContractsService.submitManualSignature(cups, file)

      onSubmitted()
    } catch (error) {
      onError()
    }
  }
}
