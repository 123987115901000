import { Coordinates } from '@domain/Coordinates'

const NOT_VALID_INSTALLATION_REASON = {
  'not-enough-panels-to-balance': 'not-enough-panels-to-balance',
  'not-viable-for-automatic-offer': 'not-viable-for-automatic-offer',
  'low-consumption': 'low-consumption',
  'rustic-estate': 'rustic-estate',
}

const VERIFIED_OFFER = 'VERIFIED'

export function Installation({
  cups = null,
  eligible,
  coordinates,
  installationTax = null,
  taxType = null,
  reason,
  currentInvoice,
  dueDate,
  informationSource,
  clientType = null,
  hasDoneEnergyReform = null,
}) {
  const notValidInstallationReason = (value) => {
    return Object.freeze(getNotValidInstallationReason(value))

    function getNotValidInstallationReason(reason) {
      return reason
        ? NOT_VALID_INSTALLATION_REASON[reason] || 'not-eligible'
        : ''
    }
  }

  return Object.freeze({
    cups,
    isEligible: eligible,
    coordinates: Coordinates(coordinates),
    tax: installationTax,
    taxType,
    reason: notValidInstallationReason(reason),
    currentInvoice,
    dueDate,
    isVerified: informationSource === VERIFIED_OFFER,
    clientType,
    hasDoneEnergyReform,
  })
}
