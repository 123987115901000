import {
  Address,
  TechnicalInfo,
  Consumption,
  SupplyPointPower,
  SupplyPoint,
} from '@modules/supply-point/domain/supply-point.types'

const ATR_THREE_TARIFF_PREFIX = '3'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatSupplyPoint = (payload: any): SupplyPoint => {
  const address: Address = {
    postalCode: payload.postalCode,
    address: payload.address,
    city: payload.city,
    province: payload.region,
  }

  const power: SupplyPointPower = {
    currentByPeriod: payload.power.currentByPeriod,
    optimizedByPeriod: hasValidOptimizedPower()
      ? payload.power.optimizedByPeriod
      : [],
  }

  const consumptionByPeriod: Consumption[] =
    payload.consumption.estimatedByPeriod

  const technicalInfo: TechnicalInfo = {
    tariff: {
      id: payload.tariff.id,
      value: payload.tariff.value,
    },
    tension: payload.tension,
  }

  return {
    address,
    power,
    consumptionByPeriod,
    technicalInfo,
  }

  function hasValidOptimizedPower() {
    return (
      !!payload.power.optimizedByPeriod.length &&
      !payload.tariff.id.startsWith(ATR_THREE_TARIFF_PREFIX)
    )
  }
}

export { formatSupplyPoint }
