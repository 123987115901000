const isBase64 = (str = '') => {
  if (typeof str !== 'string') {
    return false
  }

  if (str.trim() === '') {
    return false
  }

  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

export { isBase64 }
