import {
  getSendCoversParams,
  getSendPanelsParams,
  formatPanels,
} from './drawing.utils'
import { dataAxios } from '@infrastructure/apiService'
import { IDrawingRepository } from '@modules/drawing/domain'
import { Roof, Distribution } from '@modules/drawing/domain/drawing.types'

export const drawingService: IDrawingRepository = {
  sendCovers: async (payload: Roof) => {
    const params = getSendCoversParams(payload)

    const { data } = await dataAxios.post('', JSON.stringify(params))
    return formatPanels(data.body.panels)
  },

  sendPanels: async (payload: Distribution) => {
    const params = getSendPanelsParams(payload)
    const { data } = await dataAxios.post(
      '/create-acad',
      JSON.stringify(params)
    )
    return data.body
  },
}
