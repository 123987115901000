import { supplyPointService } from '@modules/supply-point/infrastructure/supply-point.service'
import { SupplyPoint } from '@modules/supply-point/domain/supply-point.types'

type Parameters = {
  cups: string
}

type Callbacks = {
  onSupplyPoint: (supplyPoint: SupplyPoint) => void
  onNetworkError: () => void
}

export function getSupplyPointByCups() {
  return { execute }

  async function execute(
    { cups }: Parameters,
    { onSupplyPoint, onNetworkError }: Callbacks
  ) {
    try {
      const supplyPoint = await supplyPointService.getSupplyPointByCups(cups)
      onSupplyPoint(supplyPoint)
    } catch (error) {
      onNetworkError()
    }
  }
}
