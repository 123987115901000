import { solarProductAxios } from '@infrastructure/apiService'
import { FinancingCondition } from '@domain/Financing/FinancingCondition'
import { FinancingData } from '@domain/Financing/FinancingData'

function factory(service) {
  return {
    async getFinancingConditions() {
      const { data } = await service.get(`/private/financing-conditions`)
      return data.map(FinancingCondition)
    },

    async calculateFinancingData(params) {
      const { data } = await service.get(
        '/private/offer/calculate-financing-data',
        {
          params,
        }
      )

      return FinancingData(data)
    },
  }
}

export default factory(solarProductAxios)
